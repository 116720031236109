import { createGraphQLError } from '@graphql-tools/utils';
import { dset } from 'dset';
import { isContentTypeMatch } from './utils.js';
export function isPOSTMultipartRequest(request) {
    return (request.method === 'POST' &&
        isContentTypeMatch(request, 'multipart/form-data'));
}
export async function parsePOSTMultipartRequest(request) {
    let requestBody;
    try {
        requestBody = await request.formData();
    }
    catch (e) {
        // Trick for @whatwg-node/fetch errors on Node.js
        // TODO: This needs a better solution
        if (e instanceof Error &&
            e.message.startsWith('File size limit exceeded: ')) {
            throw createGraphQLError(e.message, {
                extensions: {
                    http: {
                        status: 413,
                    },
                },
            });
        }
        throw e;
    }
    const operationsStr = requestBody.get('operations')?.toString() || '{}';
    const operations = JSON.parse(operationsStr);
    const mapStr = requestBody.get('map')?.toString() || '{}';
    const map = JSON.parse(mapStr);
    for (const fileIndex in map) {
        const file = requestBody.get(fileIndex);
        const keys = map[fileIndex];
        for (const key of keys) {
            dset(operations, key, file);
        }
    }
    return operations;
}
