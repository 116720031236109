import { Request } from '@whatwg-node/fetch';

function isAsyncIterable(body) {
    return body != null && typeof body === 'object' && typeof body[Symbol.asyncIterator] === 'function';
}
function getPort(nodeRequest) {
    var _a, _b, _c, _d, _e;
    if ((_a = nodeRequest.socket) === null || _a === void 0 ? void 0 : _a.localPort) {
        return (_b = nodeRequest.socket) === null || _b === void 0 ? void 0 : _b.localPort;
    }
    const portInHeader = (_e = (_d = (_c = nodeRequest.headers) === null || _c === void 0 ? void 0 : _c.host) === null || _d === void 0 ? void 0 : _d.split(':')) === null || _e === void 0 ? void 0 : _e[1];
    if (portInHeader) {
        return portInHeader;
    }
    return 80;
}
function getHostnameWithPort(nodeRequest) {
    var _a, _b, _c;
    if ((_a = nodeRequest.headers) === null || _a === void 0 ? void 0 : _a.host) {
        return (_b = nodeRequest.headers) === null || _b === void 0 ? void 0 : _b.host;
    }
    const port = getPort(nodeRequest);
    if (nodeRequest.hostname) {
        return nodeRequest.hostname + ':' + port;
    }
    const localIp = (_c = nodeRequest.socket) === null || _c === void 0 ? void 0 : _c.localAddress;
    if (localIp && !(localIp === null || localIp === void 0 ? void 0 : localIp.includes('::')) && !(localIp === null || localIp === void 0 ? void 0 : localIp.includes('ffff'))) {
        return `${localIp}:${port}`;
    }
    return 'localhost';
}
function buildFullUrl(nodeRequest) {
    const hostnameWithPort = getHostnameWithPort(nodeRequest);
    const protocol = nodeRequest.protocol || 'http';
    const endpoint = nodeRequest.originalUrl || nodeRequest.url || '/graphql';
    return `${protocol}://${hostnameWithPort}${endpoint}`;
}
function configureSocket(rawRequest) {
    var _a, _b, _c, _d, _e, _f;
    (_b = (_a = rawRequest === null || rawRequest === void 0 ? void 0 : rawRequest.socket) === null || _a === void 0 ? void 0 : _a.setTimeout) === null || _b === void 0 ? void 0 : _b.call(_a, 0);
    (_d = (_c = rawRequest === null || rawRequest === void 0 ? void 0 : rawRequest.socket) === null || _c === void 0 ? void 0 : _c.setNoDelay) === null || _d === void 0 ? void 0 : _d.call(_c, true);
    (_f = (_e = rawRequest === null || rawRequest === void 0 ? void 0 : rawRequest.socket) === null || _e === void 0 ? void 0 : _e.setKeepAlive) === null || _f === void 0 ? void 0 : _f.call(_e, true);
}
function isRequestBody(body) {
    const stringTag = body[Symbol.toStringTag];
    if (typeof body === 'string' ||
        stringTag === 'Uint8Array' ||
        stringTag === 'Blob' ||
        stringTag === 'FormData' ||
        stringTag === 'URLSearchParams' ||
        isAsyncIterable(body)) {
        return true;
    }
    return false;
}
function normalizeNodeRequest(nodeRequest, RequestCtor) {
    var _a;
    const rawRequest = nodeRequest.raw || nodeRequest.req || nodeRequest;
    configureSocket(rawRequest);
    let fullUrl = buildFullUrl(rawRequest);
    if (nodeRequest.query) {
        const urlObj = new URL(fullUrl);
        for (const queryName in nodeRequest.query) {
            const queryValue = nodeRequest.query[queryName];
            urlObj.searchParams.set(queryName, queryValue);
        }
        fullUrl = urlObj.toString();
    }
    const baseRequestInit = {
        method: nodeRequest.method,
        headers: nodeRequest.headers,
    };
    if (nodeRequest.method === 'GET' || nodeRequest.method === 'HEAD') {
        return new RequestCtor(fullUrl, baseRequestInit);
    }
    /**
     * Some Node server frameworks like Serverless Express sends a dummy object with body but as a Buffer not string
     * so we do those checks to see is there something we can use directly as BodyInit
     * because the presence of body means the request stream is already consumed and,
     * rawRequest cannot be used as BodyInit/ReadableStream by Fetch API in this case.
     */
    const maybeParsedBody = nodeRequest.body;
    if (maybeParsedBody != null && Object.keys(maybeParsedBody).length > 0) {
        if (isRequestBody(maybeParsedBody)) {
            return new RequestCtor(fullUrl, {
                ...baseRequestInit,
                body: maybeParsedBody,
            });
        }
        const request = new RequestCtor(fullUrl, {
            ...baseRequestInit,
        });
        if (!((_a = request.headers.get('content-type')) === null || _a === void 0 ? void 0 : _a.includes('json'))) {
            request.headers.set('content-type', 'application/json');
        }
        return new Proxy(request, {
            get: (target, prop, receiver) => {
                switch (prop) {
                    case 'json':
                        return async () => maybeParsedBody;
                    case 'text':
                        return async () => JSON.stringify(maybeParsedBody);
                    default:
                        return Reflect.get(target, prop, receiver);
                }
            },
        });
    }
    return new RequestCtor(fullUrl, {
        headers: nodeRequest.headers,
        method: nodeRequest.method,
        body: rawRequest,
    });
}
function isReadable(stream) {
    return stream.read != null;
}
function isServerResponse(stream) {
    // Check all used functions are defined
    return stream.setHeader != null && stream.end != null && stream.once != null && stream.write != null;
}
async function sendNodeResponse({ headers, status, statusText, body }, serverResponse) {
    headers.forEach((value, name) => {
        serverResponse.setHeader(name, value);
    });
    serverResponse.statusCode = status;
    serverResponse.statusMessage = statusText;
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
        if (body == null) {
            serverResponse.end(resolve);
        }
        else if (body[Symbol.toStringTag] === 'Uint8Array') {
            serverResponse.end(body, resolve);
        }
        else if (isReadable(body)) {
            serverResponse.once('close', () => {
                body.destroy();
            });
            body.pipe(serverResponse);
        }
        else if (isAsyncIterable(body)) {
            for await (const chunk of body) {
                if (!serverResponse.write(chunk)) {
                    resolve();
                    return;
                }
            }
            serverResponse.end(resolve);
        }
    });
}

/// <reference lib="webworker" />
function handleWaitUntils(waitUntilPromises) {
    return Promise.allSettled(waitUntilPromises).then(waitUntils => waitUntils.forEach(waitUntil => {
        if (waitUntil.status === 'rejected') {
            console.error(waitUntil.reason);
        }
    }));
}
function createServerAdapter({ Request: RequestCtor = Request, handleRequest, baseObject, }) {
    function fetchFn(input, init, ...ctx) {
        if (typeof input === 'string' || input instanceof URL) {
            return handleRequest(new RequestCtor(input, init), Object.assign({}, ...ctx));
        }
        return handleRequest(input, Object.assign({}, init, ...ctx));
    }
    function handleNodeRequest(nodeRequest, serverContext) {
        const request = normalizeNodeRequest(nodeRequest, RequestCtor);
        return handleRequest(request, serverContext);
    }
    async function requestListener(nodeRequest, serverResponse) {
        const waitUntilPromises = [];
        const response = await handleNodeRequest(nodeRequest, {
            req: nodeRequest,
            res: serverResponse,
            waitUntil(p) {
                waitUntilPromises.push(p);
            },
        });
        if (response) {
            await sendNodeResponse(response, serverResponse);
        }
        else {
            await new Promise(resolve => {
                serverResponse.statusCode = 404;
                serverResponse.end(resolve);
            });
        }
        await handleWaitUntils(waitUntilPromises);
    }
    function handleEvent(event) {
        if (!event.respondWith || !event.request) {
            throw new TypeError(`Expected FetchEvent, got ${event}`);
        }
        const response$ = handleRequest(event.request, event);
        event.respondWith(response$);
    }
    const adapterObj = {
        handleRequest,
        fetch: fetchFn,
        handleNodeRequest,
        requestListener,
        handleEvent,
        handle: requestListener,
    };
    function genericRequestHandler(input, ctx, ...rest) {
        var _a;
        if ('process' in globalThis && ((_a = process.versions) === null || _a === void 0 ? void 0 : _a['bun']) != null) {
            // This is required for bun
            input.text();
        }
        // If it is a Node request
        if (isReadable(input) && ctx != null && isServerResponse(ctx)) {
            return requestListener(input, ctx);
        }
        // Is input a container object over Request?
        if (input.request) {
            // Is it FetchEvent?
            if (input.respondWith) {
                return handleEvent(input);
            }
            // In this input is also the context
            return handleRequest(input.request, input);
        }
        // Or is it Request itself?
        // Then ctx is present and it is the context
        if ((rest === null || rest === void 0 ? void 0 : rest.length) > 0) {
            ctx = Object.assign({}, ctx, ...rest);
        }
        if (!ctx.waitUntil) {
            const waitUntilPromises = [];
            ctx.waitUntil = (p) => {
                waitUntilPromises.push(p);
            };
            const response$ = handleRequest(input, {
                ...ctx,
                waitUntil(p) {
                    waitUntilPromises.push(p);
                },
            });
            if (waitUntilPromises.length > 0) {
                return handleWaitUntils(waitUntilPromises).then(() => response$);
            }
        }
        return handleRequest(input, ctx);
    }
    return new Proxy(genericRequestHandler, {
        // It should have all the attributes of the handler function and the server instance
        has: (_, prop) => {
            return (baseObject && prop in baseObject) || prop in adapterObj || prop in genericRequestHandler;
        },
        get: (_, prop) => {
            if (baseObject) {
                if (prop in baseObject) {
                    if (baseObject[prop].bind) {
                        return baseObject[prop].bind(baseObject);
                    }
                    return baseObject[prop];
                }
            }
            if (adapterObj[prop]) {
                if (adapterObj[prop].bind) {
                    return adapterObj[prop].bind(adapterObj);
                }
                return adapterObj[prop];
            }
            if (genericRequestHandler[prop]) {
                if (genericRequestHandler[prop].bind) {
                    return genericRequestHandler[prop].bind(genericRequestHandler);
                }
                return genericRequestHandler[prop];
            }
        },
        apply(_, __, [input, ctx]) {
            return genericRequestHandler(input, ctx);
        },
    });
}

export { createServerAdapter };
