import { print } from 'graphql';
import { envelop, useMaskedErrors, useExtendContext, useLogger, } from '@envelop/core';
import { useValidationCache } from '@envelop/validation-cache';
import { useParserCache } from '@envelop/parser-cache';
import { createFetch } from '@whatwg-node/fetch';
import { createServerAdapter } from '@whatwg-node/server';
import { processRequest as processGraphQLParams, processResult, } from './processRequest.js';
import { defaultYogaLogger, titleBold } from './logger.js';
import { useCORS } from './plugins/useCORS.js';
import { useHealthCheck } from './plugins/useHealthCheck.js';
import { useGraphiQL, } from './plugins/useGraphiQL.js';
import { useRequestParser } from './plugins/useRequestParser.js';
import { isGETRequest, parseGETRequest } from './plugins/requestParser/GET.js';
import { isPOSTJsonRequest, parsePOSTJsonRequest, } from './plugins/requestParser/POSTJson.js';
import { isPOSTMultipartRequest, parsePOSTMultipartRequest, } from './plugins/requestParser/POSTMultipart.js';
import { isPOSTGraphQLStringRequest, parsePOSTGraphQLStringRequest, } from './plugins/requestParser/POSTGraphQLString.js';
import { useResultProcessor } from './plugins/useResultProcessor.js';
import { processRegularResult } from './plugins/resultProcessor/regular.js';
import { processPushResult } from './plugins/resultProcessor/push.js';
import { processMultipartResult } from './plugins/resultProcessor/multipart.js';
import { isPOSTFormUrlEncodedRequest, parsePOSTFormUrlEncodedRequest, } from './plugins/requestParser/POSTFormUrlEncoded.js';
import { handleError } from './error.js';
import { useCheckMethodForGraphQL } from './plugins/requestValidation/useCheckMethodForGraphQL.js';
import { useCheckGraphQLQueryParams } from './plugins/requestValidation/useCheckGraphQLQueryParams.js';
import { useHTTPValidationError } from './plugins/requestValidation/useHTTPValidationError.js';
import { usePreventMutationViaGET } from './plugins/requestValidation/usePreventMutationViaGET.js';
import { useUnhandledRoute } from './plugins/useUnhandledRoute.js';
import { yogaDefaultFormatError } from './utils/yogaDefaultFormatError.js';
import { useSchema } from './plugins/useSchema.js';
import { useLimitBatching } from './plugins/requestValidation/useLimitBatching.js';
/**
 * Base class that can be extended to create a GraphQL server with any HTTP server framework.
 * @internal
 */
export class YogaServer {
    constructor(options) {
        this.handleRequest = async (request, ...args) => {
            try {
                const response = await this.getResponse(request, ...args);
                for (const onResponseHook of this.onResponseHooks) {
                    await onResponseHook({
                        request,
                        response,
                        serverContext: args[0],
                    });
                }
                return response;
            }
            catch (e) {
                this.logger.error(e);
                return new this.fetchAPI.Response('Internal Server Error', {
                    status: 500,
                });
            }
        };
        this.id = options?.id ?? 'yoga';
        this.fetchAPI =
            options?.fetchAPI ??
                createFetch({
                    useNodeFetch: true,
                });
        const logger = options?.logging != null ? options.logging : true;
        this.logger =
            typeof logger === 'boolean'
                ? logger === true
                    ? defaultYogaLogger
                    : {
                        debug: () => { },
                        error: () => { },
                        warn: () => { },
                        info: () => { },
                    }
                : logger;
        this.maskedErrorsOpts =
            options?.maskedErrors === false
                ? null
                : {
                    formatError: yogaDefaultFormatError,
                    errorMessage: 'Unexpected error.',
                    isDev: globalThis.process?.env?.NODE_ENV === 'development',
                    ...(typeof options?.maskedErrors === 'object'
                        ? options.maskedErrors
                        : {}),
                };
        const maskedErrors = this.maskedErrorsOpts != null ? this.maskedErrorsOpts : null;
        this.graphqlEndpoint = options?.graphqlEndpoint || '/graphql';
        const graphqlEndpoint = this.graphqlEndpoint;
        this.plugins = [
            // Use the schema provided by the user
            !!options?.schema && useSchema(options.schema),
            // Performance things
            options?.parserCache !== false &&
                useParserCache(typeof options?.parserCache === 'object'
                    ? options.parserCache
                    : undefined),
            options?.validationCache !== false &&
                useValidationCache({
                    cache: typeof options?.validationCache === 'object'
                        ? options.validationCache
                        : undefined,
                }),
            // Log events - useful for debugging purposes
            logger !== false &&
                useLogger({
                    skipIntrospection: true,
                    logFn: (eventName, events) => {
                        switch (eventName) {
                            case 'execute-start':
                            case 'subscribe-start':
                                this.logger.debug(titleBold('Execution start'));
                                const { params: { query, operationName, variables, extensions }, } = events.args.contextValue;
                                this.logger.debug(titleBold('Received GraphQL operation:'));
                                this.logger.debug({
                                    query,
                                    operationName,
                                    variables,
                                    extensions,
                                });
                                break;
                            case 'execute-end':
                            case 'subscribe-end':
                                this.logger.debug(titleBold('Execution end'));
                                this.logger.debug({
                                    result: events.result,
                                });
                                break;
                        }
                    },
                }),
            options?.context != null &&
                useExtendContext(async (initialContext) => {
                    if (options?.context) {
                        if (typeof options.context === 'function') {
                            return options.context(initialContext);
                        }
                        return options.context;
                    }
                }),
            // Middlewares before processing the incoming HTTP request
            useHealthCheck({
                id: this.id,
                logger: this.logger,
                healthCheckEndpoint: options?.healthCheckEndpoint,
                readinessCheckEndpoint: options?.readinessCheckEndpoint,
            }),
            options?.cors !== false && useCORS(options?.cors),
            options?.graphiql !== false &&
                useGraphiQL({
                    graphqlEndpoint: this.graphqlEndpoint,
                    options: options?.graphiql,
                    render: options?.renderGraphiQL,
                    logger: this.logger,
                }),
            // Middlewares before the GraphQL execution
            useCheckMethodForGraphQL(),
            useRequestParser({
                match: isGETRequest,
                parse: parseGETRequest,
            }),
            useRequestParser({
                match: isPOSTJsonRequest,
                parse: parsePOSTJsonRequest,
            }),
            options?.multipart !== false &&
                useRequestParser({
                    match: isPOSTMultipartRequest,
                    parse: parsePOSTMultipartRequest,
                }),
            useRequestParser({
                match: isPOSTGraphQLStringRequest,
                parse: parsePOSTGraphQLStringRequest,
            }),
            useRequestParser({
                match: isPOSTFormUrlEncodedRequest,
                parse: parsePOSTFormUrlEncodedRequest,
            }),
            // Middlewares after the GraphQL execution
            useResultProcessor({
                mediaTypes: ['multipart/mixed'],
                processResult: processMultipartResult,
            }),
            useResultProcessor({
                mediaTypes: ['text/event-stream'],
                processResult: processPushResult,
            }),
            useResultProcessor({
                mediaTypes: ['application/graphql-response+json', 'application/json'],
                processResult: processRegularResult,
            }),
            ...(options?.plugins ?? []),
            useLimitBatching(options?.batchingLimit),
            useCheckGraphQLQueryParams(),
            useUnhandledRoute({
                graphqlEndpoint,
                showLandingPage: options?.landingPage ?? true,
            }),
            // We make sure that the user doesn't send a mutation with GET
            usePreventMutationViaGET(),
            // To make sure those are called at the end
            {
                onPluginInit({ addPlugin }) {
                    if (maskedErrors) {
                        addPlugin(useMaskedErrors(maskedErrors));
                    }
                    addPlugin(
                    // We handle validation errors at the end
                    useHTTPValidationError());
                },
            },
        ];
        this.getEnveloped = envelop({
            plugins: this.plugins,
        });
        this.onRequestHooks = [];
        this.onRequestParseHooks = [];
        this.onParamsHooks = [];
        this.onResultProcessHooks = [];
        this.onResponseHooks = [];
        for (const plugin of this.plugins) {
            if (plugin) {
                if (plugin.onRequest) {
                    this.onRequestHooks.push(plugin.onRequest);
                }
                if (plugin.onRequestParse) {
                    this.onRequestParseHooks.push(plugin.onRequestParse);
                }
                if (plugin.onParams) {
                    this.onParamsHooks.push(plugin.onParams);
                }
                if (plugin.onResultProcess) {
                    this.onResultProcessHooks.push(plugin.onResultProcess);
                }
                if (plugin.onResponse) {
                    this.onResponseHooks.push(plugin.onResponse);
                }
            }
        }
    }
    async getResultForParams({ params, request, }, ...args) {
        try {
            let result;
            for (const onParamsHook of this.onParamsHooks) {
                await onParamsHook({
                    params,
                    request,
                    setParams(newParams) {
                        params = newParams;
                    },
                    setResult(newResult) {
                        result = newResult;
                    },
                });
            }
            if (result == null) {
                const serverContext = args[0];
                const initialContext = {
                    ...serverContext,
                    request,
                    params,
                };
                const enveloped = this.getEnveloped(initialContext);
                this.logger.debug(`Processing GraphQL Parameters`);
                result = await processGraphQLParams({
                    params,
                    enveloped,
                });
            }
            return result;
        }
        catch (error) {
            const errors = handleError(error, this.maskedErrorsOpts);
            const result = {
                errors,
            };
            return result;
        }
    }
    async getResponse(request, ...args) {
        const serverContext = args[0];
        const url = new URL(request.url, 'http://localhost');
        for (const onRequestHook of this.onRequestHooks) {
            let response;
            await onRequestHook({
                request,
                serverContext,
                fetchAPI: this.fetchAPI,
                url,
                endResponse(newResponse) {
                    response = newResponse;
                },
            });
            if (response) {
                return response;
            }
        }
        let requestParser;
        const onRequestParseDoneList = [];
        let result;
        try {
            for (const onRequestParse of this.onRequestParseHooks) {
                const onRequestParseResult = await onRequestParse({
                    request,
                    requestParser,
                    setRequestParser(parser) {
                        requestParser = parser;
                    },
                });
                if (onRequestParseResult?.onRequestParseDone != null) {
                    onRequestParseDoneList.push(onRequestParseResult.onRequestParseDone);
                }
            }
            this.logger.debug(`Parsing request to extract GraphQL parameters`);
            if (!requestParser) {
                return new this.fetchAPI.Response('Request is not valid', {
                    status: 400,
                    statusText: 'Bad Request',
                });
            }
            let requestParserResult = await requestParser(request);
            for (const onRequestParseDone of onRequestParseDoneList) {
                await onRequestParseDone({
                    requestParserResult,
                    setRequestParserResult(newParams) {
                        requestParserResult = newParams;
                    },
                });
            }
            result = (await (Array.isArray(requestParserResult)
                ? Promise.all(requestParserResult.map((params) => this.getResultForParams({
                    params,
                    request,
                }, ...args)))
                : this.getResultForParams({
                    params: requestParserResult,
                    request,
                }, ...args)));
        }
        catch (error) {
            const errors = handleError(error, this.maskedErrorsOpts);
            result = {
                errors,
            };
        }
        const response = await processResult({
            request,
            result,
            fetchAPI: this.fetchAPI,
            onResultProcessHooks: this.onResultProcessHooks,
        });
        return response;
    }
    /**
     * Testing utility to mock http request for GraphQL endpoint
     *
     *
     * Example - Test a simple query
     * ```ts
     * const { response, executionResult } = await yoga.inject({
     *  document: "query { ping }",
     * })
     * expect(response.status).toBe(200)
     * expect(executionResult.data.ping).toBe('pong')
     * ```
     **/
    async inject({ document, variables, operationName, headers, serverContext, }) {
        const request = new this.fetchAPI.Request('http://localhost' + this.graphqlEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...headers,
            },
            body: JSON.stringify({
                query: document &&
                    (typeof document === 'string' ? document : print(document)),
                variables,
                operationName,
            }),
        });
        const response = await this.handleRequest(request, serverContext);
        let executionResult = null;
        if (response.headers.get('content-type') === 'application/json') {
            executionResult = await response.json();
        }
        return {
            response,
            executionResult,
        };
    }
}
export function createYoga(options) {
    const server = new YogaServer(options);
    return createServerAdapter({
        baseObject: server,
        handleRequest: server.handleRequest,
        Request: server.fetchAPI.Request,
    });
}
