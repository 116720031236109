const ANSI_CODES = {
    black: '\x1b[30m',
    red: '\x1b[31m',
    green: '\x1b[32m',
    yellow: '\x1b[33m',
    blue: '\x1b[34m',
    magenta: '\x1b[35m',
    cyan: '\x1b[36m',
    white: '\x1b[37m',
    reset: '\x1b[0m',
    bold: '\x1b[1m',
    orange: '\x1b[48:5:166m',
};
export const warnColor = (msg) => ANSI_CODES.orange + msg + ANSI_CODES.reset;
export const infoColor = (msg) => ANSI_CODES.cyan + msg + ANSI_CODES.reset;
export const errorColor = (msg) => ANSI_CODES.red + msg + ANSI_CODES.reset;
export const debugColor = (msg) => ANSI_CODES.magenta + msg + ANSI_CODES.reset;
export const titleBold = (msg) => ANSI_CODES.bold + msg + ANSI_CODES.reset;
const LEVEL_COLOR = {
    warn: ANSI_CODES.orange,
    info: ANSI_CODES.cyan,
    error: ANSI_CODES.red,
    debug: ANSI_CODES.magenta,
    title: ANSI_CODES.bold,
    reset: ANSI_CODES.reset,
};
const isDebug = () => !!globalThis.process?.env?.DEBUG;
const prefix = [LEVEL_COLOR.title, `🧘 Yoga -`, LEVEL_COLOR.reset];
export const defaultYogaLogger = {
    debug(...args) {
        if (isDebug()) {
            const fullMessage = [
                `🐛 `,
                ...prefix,
                LEVEL_COLOR.debug,
                ...args,
                LEVEL_COLOR.reset,
            ];
            // Some environments don't have other console methods
            if (console.debug) {
                console.debug(...fullMessage);
            }
            else {
                console.log(...fullMessage);
            }
        }
    },
    info(...args) {
        const fullMessage = [
            `💡 `,
            ...prefix,
            LEVEL_COLOR.info,
            ...args,
            LEVEL_COLOR.reset,
        ];
        if (console.info) {
            console.info(...fullMessage);
        }
        else {
            console.log(...fullMessage);
        }
    },
    warn(...args) {
        const fullMessage = [
            `⚠️ `,
            ...prefix,
            LEVEL_COLOR.warn,
            ...args,
            LEVEL_COLOR.reset,
        ];
        if (console.warn) {
            console.warn(...fullMessage);
        }
        else {
            console.log(...fullMessage);
        }
    },
    error(...args) {
        const fullMessage = [
            `❌ `,
            ...prefix,
            LEVEL_COLOR.error,
            ...args,
            LEVEL_COLOR.reset,
        ];
        if (console.error) {
            console.error(...fullMessage);
        }
        else {
            console.log(...fullMessage);
        }
    },
};
