import { createGraphQLError } from '@graphql-tools/utils';
import { isContentTypeMatch } from './utils.js';
export function isPOSTJsonRequest(request) {
    return (request.method === 'POST' &&
        (isContentTypeMatch(request, 'application/json') ||
            isContentTypeMatch(request, 'application/graphql+json')));
}
export async function parsePOSTJsonRequest(request) {
    try {
        const requestBody = await request.json();
        if (typeof requestBody !== 'object' || requestBody == null) {
            throw createGraphQLError('POST body sent invalid JSON.', {
                extensions: {
                    http: {
                        status: 400,
                    },
                },
            });
        }
        return requestBody;
    }
    catch (err) {
        throw createGraphQLError('POST body sent invalid JSON.', {
            extensions: {
                http: {
                    status: 400,
                },
            },
        });
    }
}
